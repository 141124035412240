import React, {useEffect, useState} from 'react';
import {
    useMediaQuery,
    useTheme,
    Button, CircularProgress
} from "@mui/material";
import Chip from '@mui/material/Chip';
import PosOptionBox from "./PosOptionBox";
import {FaLongArrowAltLeft, FaSearch} from "react-icons/fa";
import {FaRegCreditCard} from "react-icons/fa6";
import CloseIcon from '@mui/icons-material/Close';
import {money_format, number_format} from "./functions/numberFormat";
import {BsCashStack} from "react-icons/bs";
import {IoQrCodeOutline} from "react-icons/io5";
import SuccessIcon from "./SuccessIcon";
import OrderSummary from "./OrderSummary";
import OrderContainer from "./OrderContainer";
import { FaPlus } from "react-icons/fa6";
import {HiSwitchHorizontal} from "react-icons/hi";
import {useNavigate} from "react-router-dom";
import Swal from "sweetalert2";
import {printOrder, printOrderInSession} from "./functions/printOrder";
import {reducedNumberFormatter} from "./functions/numberFormat";
import {MdPointOfSale} from "react-icons/md";
import {removeSession} from "../utils/session";


const OrderListInterface = (props) => {

    const navigate = useNavigate();
    const session = JSON.parse(localStorage.getItem('session'));

    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [items, setItems] = useState([]);
    const [itemsAux, setItemsAux] = useState([]);
    const [variantsAux, setVariantsAux] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedVariants, setSelectedVariants] = useState([]);
    const [posQuantity, setPosQuantity] = useState(1);
    const [paymentMethod, setPaymentMethod] = useState('cash');
    const [displayPaymentMethod, setDisplayPaymentMethod] = useState(false);
    const [waitingForPayment, setWaitingForPayment] = useState(false);
    const [paymentSuccess, setPaymentSuccess] = useState(false);
    const [paymentError, setPaymentError] = useState(false);
    const [paymentErrorMessage, setPaymentErrorMessage] = useState('');
    const [orderID, setOrderID] = useState(null);
    const [waitingForPrinting, setWaitingForPrinting] = useState(false);
    const [ordersFilter, setOrdersFilter] = useState('open');
    const [orders, setOrders] = useState([]);
    const [ordersAux, setOrdersAux] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [paymentAmountError, setPaymentAmountError] = useState(false);
    const [paymentAmountErrorMessage, setPaymentAmountErrorMessage] = useState(false);
    const [paymentAmount, setPaymentAmount] = useState(0);
    const [mobileSummaryOpen, setMobileSummaryOpen] = useState(false);
    const [loadingOrders, setLoadingOrders] = useState(false);

    const [values, setValues] = useState({
        items: [],
    });

    const handleUnselectCategory = (id) => {
        setSelectedCategories(selectedCategories.filter(categoryId => categoryId !== id));
    }

    const handleSelectCategory = (id) => {
        setSelectedCategories( [id])
    }

    const fetchData = async () => {
        setLoadingOrders(true);

        // Retrieve client's timezone identifier
        const timeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone;

        // Retrieve client's time difference in minutes and its direction
        const moment = require('moment-timezone');
        const gmtOffset = moment.tz(timeZone).utcOffset();


        const request = await fetch(`https://api.flashpass.com.ar/menu-orders?type=seller&session_id=${session?.session_id}&pos_id=${session?.pos_id}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('token'),
                "credentials": "same-origin",
            }
        });

        const response = await request?.json();

        if (response?.status === 'success') {

            const ordersAux = response.data.map(order => {
                const order_date = new Date(order.order_date);
                // Apply GMT offset
                order_date.setMinutes(order_date.getMinutes() + gmtOffset);

                // Get current date with GMT offset
                const now = new Date(new Date().getTime() + gmtOffset * 60000);

                // Function to strip time from a date object
                const stripTime = (date) => new Date(date.getFullYear(), date.getMonth(), date.getDate());

                // Get today's and yesterday's dates at 00:00:00
                const today = stripTime(now);
                const yesterday = new Date(today);
                yesterday.setDate(yesterday.getDate() - 1);

                // Format date and time
                const formatDate = (date) => date.toLocaleDateString('es-AR');
                const formatTime = (date) => date.toTimeString().split(' ')[0];

                // Determine if the order date is today, yesterday, or another day
                const strippedOrderDate = stripTime(order_date);
                let order_date_desc;

                if (strippedOrderDate.getTime() === today.getTime()) {
                    order_date_desc = `Hoy a las ${formatTime(order_date)}`;
                } else if (strippedOrderDate.getTime() === yesterday.getTime()) {
                    order_date_desc = `Ayer a las ${formatTime(order_date)}`;
                } else {
                    order_date_desc = `${formatDate(order_date)} a las ${formatTime(order_date)}`;
                }

                return {
                    ...order,
                    order_date: `${formatDate(order_date)} ${formatTime(order_date)}`,
                    order_date_desc
                }
            });

            //order them by date
            ordersAux.sort((a, b) => {
                return new Date(b.order_date) - new Date(a.order_date);
            }).map((order, index) => {
                return {
                    ...order,
                    id: index,
                    internal_status: (order?.order_status_raw === 'delivered' || order?.order_status_raw === 'cancelled') ? 'closed' : 'open',
                }
            });

            //filter them by open or closed
            const filteredOrders = ordersAux.filter(order => {
                if (ordersFilter === 'open') {
                    return order?.order_status_raw === 'placed';
                }
                else {
                    return order?.order_status_raw === 'processing' || order?.order_status_raw === 'delivered' || order?.order_status_raw === 'cancelled';
                }
            });

            setOrders(ordersAux);

            const firstOrder = ordersAux[0];

            if (!isScreenWidthLessThanMd()) {
                setOrderID(ordersAux[0]?.order_hash);
            }

            if (firstOrder?.order_status_raw === 'placed') {
                setOrdersFilter('open');
            } else {
                setOrdersFilter('closed');
            }

            setOrdersAux(filteredOrders);
        }
        setLoadingOrders(false);
    }

    const isScreenWidthLessThanMd = () => {
        const mdBreakpoint = 768; // Define the md breakpoint in pixels
        return window.innerWidth < mdBreakpoint;
    };

    const fetchOrder = async (id) => {
        const request = await fetch(`${process.env.REACT_APP_API_V2_URL}/menus/orders/${id}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('token'),
                "credentials": "same-origin",
            },
        });

        const response = await request?.json();

        const products = response?.products;

        if (products) {
            const newValues = {
                ...response,
                items: products.map(product => {
                    return {
                        item: {
                            productId: product.id,
                            title: product.title,
                            description: product.description,
                            price: product.price,
                            categoryId: product.category_id,
                            categoryName: product.category_name,
                            variants: product.variants.map(variant => {
                                return {
                                    id: variant.variant_id,
                                    title: variant.name,
                                    price: variant.price,
                                    category_id: variant.category_id,
                                    categoryName: variant.category_name,
                                }
                            }),
                        },
                        variants: product.variants.map(variant => {
                            return {
                                id: variant.variant_id,
                                title: variant.name,
                                price: variant.price,
                                category_id: variant.category_id,
                                categoryName: variant.category_name,
                            }
                        }),
                        quantity: product.quantity,
                    }
                }),
            };

            setValues(newValues);
            setPaymentAmount(response?.remaining_amount);
        }
        setIsLoading(false);
    }

    const handleSelectVariant = (variant, category) => {
        if (!variant || !variant.category_id) {
            console.error("Invalid variant provided");
            return;
        }

        variant.categoryName = category.title;

        setSelectedVariants(prevSelectedVariants => {
            const existingIndex = prevSelectedVariants.findIndex(v => v.category_id === variant.category_id);

            if (existingIndex >= 0) {
                // Replace existing variant in the same category
                const newVariants = [...prevSelectedVariants];
                newVariants[existingIndex] = variant;
                return newVariants;
            } else {
                // Add new variant as it's in a different category
                return [...prevSelectedVariants, variant];
            }
        });
    };

    const addItem = () => {
        const item = selectedItems[0];
        const variants = selectedVariants;

        const newValues = {
            items: [
                ...values.items,
                {
                    item: item,
                    variants: variants,
                    quantity: posQuantity,
                }
            ]
        };

        setValues(newValues);
        setSelectedItems([]);
        setSelectedVariants([]);
        setPosQuantity(1);
    }

    const goToPaymentMethod = () => {
        setDisplayPaymentMethod(true);
    }

    const userChangeConfirm = () => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: "Si cambias de usuario, cerrarás la sesión actual.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Cambiar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                localStorage.removeItem('token');
                removeSession()
                navigate('/login');
            }
        });
    }
    const submitOrder = async () => {

        const request = await fetch(`https://api.flashpass.com.ar/pos-checkout`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('token'),
                "credentials": "same-origin",
            },
            body: JSON.stringify({
                "total_amount": values.items.reduce((acc, item) => acc + (item.item.price * item.quantity), 0),
                "payment_method": paymentMethod,
                "menu": props.menu,
                "items": values.items.map(item => {
                    return {
                        "id": item.item.productId,
                        "title": item.item.title,
                        "description": item.item.description,
                        "quantity": item.quantity,
                        "unit_price": item.item.price,
                        "category_id": item.item.categoryId,
                        "variants": item.variants.map(variant => {
                            return {
                                "category_id": variant.category_id,
                                "variant_id": variant.id,
                                "title": variant.title,
                                "price": variant.price,
                            }
                        })
                    }
                })
            })
        });

        const response = await request?.json();

        if (response?.status === 201 || response?.status === 204) {
            setOrderID(response?.order_id);
            setWaitingForPayment(true);
        }
    }

    const cancelPaymentIntent = async () => {
        setWaitingForPayment(false);
        setPaymentError(false);
        setPaymentSuccess(false);
        setPaymentErrorMessage('');
        setDisplayPaymentMethod(true);

        if (paymentMethod === 'card') {
            await fetch(`https://api.flashpass.com.ar/mp-point-cancel-payment-intent?order=${orderID}`, {
                method: 'DELETE',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem('token'),
                    "credentials": "same-origin",
                }
            });
        }
        else if (paymentMethod === 'qr') {
            await fetch(`https://api.flashpass.com.ar/mp-qr-cancel-payment-intent?order=${orderID}`, {
                method: 'DELETE',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem('token'),
                    "credentials": "same-origin",
                }
            });
        }

        setOrderID(null);
    }

    const checkPaymentStatus = async () => {
        const request = await fetch(`https://api.flashpass.com.ar/menu-orders?order_id=${orderID}&action=view`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('token'),
                "credentials": "same-origin",
            },
        });

        const response = await request?.json();

        if (response?.order_status === 'processing' || response?.order_status === 'delivered') {
            setPaymentSuccess(true);
            setWaitingForPayment(false);
            setPaymentError(false);
        }

        else if (response?.order_status === 'placed') {
            setTimeout(() => {
                checkPaymentStatus();
            }, 1000);
        }

        else if (response?.order_status === 'cancelled') {
            setPaymentErrorMessage('El pago fue cancelado');
            setWaitingForPayment(false);
            setPaymentSuccess(false);
        }

        else {
            setPaymentError(true);
            setPaymentErrorMessage(response.error);
            setWaitingForPayment(false);
            setPaymentSuccess(false);
        }
    }

    const ordersFilterChange = async () => {
        const ordersFiltered = orders.filter(order => {
            if (ordersFilter === 'open') {
                return order?.order_status_raw === 'placed';
            }
            else {
                return order?.order_status_raw === 'processing' || order?.order_status_raw === 'delivered' || order?.order_status_raw === 'cancelled';
            }
        });

        setOrdersAux(ordersFiltered);
    }

    useEffect(() => {
        console.log(values);
    }, [values]);

    useEffect(() => {
        ordersFilterChange();
    }, [ordersFilter]);

    useEffect(() => {
        if (waitingForPayment) {
            checkPaymentStatus();
        }
    }, [waitingForPayment]);

    useEffect(() => {
        if (paymentSuccess) {
            setTimeout(() => {
                setPaymentSuccess(false);
                setDisplayPaymentMethod(false);
                setValues({
                    items: [],
                });
                setPaymentMethod('cash');
                setOrderID(null);
            }, 5000);
        }
    }, [paymentSuccess]);

    useEffect(() => {
        if (orderID) {
            setMobileSummaryOpen(true);
        } else {
            setMobileSummaryOpen(false);
        }
    }, [orderID]);

    const searchInvoice = async () => {
        const request = await fetch(`https://api.flashpass.com.ar/facturante-menu-webhook?order=${orderID}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('token'),
                "credentials": "same-origin",
            }
        });

        const responseCode = await request?.status;
        if (responseCode === 200) {
            const response = await request?.json();

            if (response?.status === 'success') {
                printOrder(orderID);
            }
        } else {
            setTimeout(() => {
                searchInvoice();
            }, 1000);
        }
    }

    useEffect(() => {
        if (waitingForPrinting) {
            searchInvoice();
        }
    }, [waitingForPrinting]);

    useEffect(() => {
        //search in every items different category_id and get the category name and id in an object
        const categoriesAux = items.map(item => {
            return {
                id: item.categoryId,
                name: item.categoryName,
            }
        });

        //remove duplicates
        const uniqueCategories = categoriesAux.filter((category, index) => {
            return categoriesAux.findIndex(obj => obj.id === category.id) === index;
        });

        //order them alphabetically
        uniqueCategories.sort((a, b) => a.name.localeCompare(b.name)).map((item, index) => {
            return {
                id: item.id,
                name: item.name,
            }
        });

        setCategories(uniqueCategories);
    }, [items]);

    useEffect(() => {
        if (selectedCategories.length === 0) {
            setItemsAux([]);
            return;
        }

        const filteredProducts = items.filter(item => {
            return selectedCategories.includes(item.categoryId);
        });

        setItemsAux(filteredProducts);
        setSelectedItems([]);
    }, [selectedCategories]);

    useEffect(() => {
        setVariantsAux(selectedItems.flatMap(item => item.variants));
        setSelectedVariants([]);
    }, [selectedItems]);

    useEffect(() => {
        const requests = setTimeout(() => {
            fetchData();
        }, 300);
        return () => clearTimeout(requests);
    }, []);

    useEffect(() => {
        setIsLoading(true);
        const requests = setTimeout(() => {
            if (orderID) {
                fetchOrder(orderID);
            }
        }, 100);
        return () => clearTimeout(requests);
    }, [orderID]);

    const searchProduct = (event) => {
        const search = event.target.value;

        //search by product title or category name
        const filteredProducts = items.filter(item => {
            return item.title.toLowerCase().includes(search.toLowerCase()) || item.categoryName.toLowerCase().includes(search.toLowerCase());
        });

        setItemsAux(filteredProducts);
    }


    return (
        <div className="pos-order-list-interface">
            <div className="pos-parent-container">
                <div className="row" style={{marginLeft: "10px"}}>
                    <div className={`col-md-4 ${(mobileSummaryOpen && !displayPaymentMethod) || orderID || waitingForPayment || paymentSuccess ? "d-block" : "d-none d-md-block"}`}>
                        <OrderSummary
                            values={values}
                            displayPaymentMethod={displayPaymentMethod}
                            setDisplayPaymentMethod={setDisplayPaymentMethod}
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                            waitingForPayment={waitingForPayment}
                            paymentSuccess={paymentSuccess}
                            paymentError={paymentError}
                            setPaymentMethod={setPaymentMethod}
                            paymentMethod={paymentMethod}
                            orderID={orderID}
                            setOrderID={setOrderID}
                            cancelPaymentIntent={cancelPaymentIntent}
                            submitOrder={submitOrder}
                            goToPaymentMethod={goToPaymentMethod}
                            orderRevision={true}
                            printOrder={printOrder}
                            printOrderInSession={printOrderInSession}
                            mobileSummaryOpen={mobileSummaryOpen}
                            setMobileSummaryOpen={setMobileSummaryOpen}
                            mode={"orders"}
                        />
                    </div>
                    <div className={`col-md-8 ${!orderID && (!mobileSummaryOpen && !displayPaymentMethod && !waitingForPayment && !paymentSuccess) ? "d-block" : "d-none d-md-block"}`}>
                        <div className="row mt-2">
                            <div className="col-12 text-end justify-content-end align-items-center d-flex">
                                <button style={{border: "none", background: "none", margin: 0}}
                                        onClick={() => navigate('/orders/new')}>
                                    <div className="option-top-bar">
                                        <FaPlus className="me-1 d-none d-md-block"/> Nueva orden
                                    </div>
                                </button>
                                <div className="option-top-bar" onClick={() => navigate("/home")}>
                                    <MdPointOfSale className="me-1 d-none d-md-block"/> Gestión de cajas
                                </div>
                                <div className="option-top-bar" onClick={userChangeConfirm}>
                                    <HiSwitchHorizontal className="me-1 d-none d-md-block"/> Cambiar Usuario
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3">
                            {orders.length === 0 && loadingOrders ? (
                                <div className="col-12">
                                    <div className="d-flex justify-content-center align-items-center" style={{height: "50vh"}}>
                                        <CircularProgress color="secondary" size={100} />
                                    </div>
                                </div>
                            ) : orders.length === 0 && !loadingOrders ? (
                                <div className="col-12">
                                    <div className="d-flex justify-content-center align-items-center" style={{height: "50vh"}}>
                                        <p>No hay ordenes</p>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <div className="col-auto">
                                        <div className="options-big-options-container">
                                            <div
                                                className={`options-big-icon ${ordersFilter === "open" ? "options-big-icon-selected" : ""}`}
                                                onClick={() => setOrdersFilter('open')}>
                                                Abiertas
                                                <div className="options-big-icon-number ms-1">
                                                    {reducedNumberFormatter(orders.filter(order => order.order_status_raw === 'placed').length)}
                                                </div>
                                            </div>
                                            <div
                                                className={`options-big-icon ${ordersFilter === "closed" ? "options-big-icon-selected" : ""}`}
                                                onClick={() => setOrdersFilter('closed')}>
                                                Cerradas
                                                <div className="options-big-icon-number ms-1">
                                                    {reducedNumberFormatter(orders.filter(order => order.order_status_raw === 'processing' || order.order_status_raw === 'delivered' || order.order_status_raw === 'cancelled').length)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col mt-2 mt-md-0">
                                        <div className="search-container">
                                            <FaSearch className="search-icon"/>
                                            <input type="text" placeholder="Buscar..." onInput={searchProduct}/>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="row">
                            <div className="col-12 mt-3 pb-5"
                                 style={{overflowY: "auto", maxHeight: "calc(100vh - 120px)"}}>
                                <div className="row pb-4">
                                    {ordersAux?.map((order, index) => (
                                        <div className="col-6 mt-2" key={index}>
                                            <OrderContainer
                                                key={index}
                                                id={order.id}
                                                title={`${order.products_count > 1 ? `${order.products_count} productos` : `${order.products_count} producto`}`}
                                                date_started={order.order_date_desc}
                                                price={order.order_total}
                                                hash={order.order_hash}
                                                selected={order.order_hash === orderID}
                                                setOrderID={setOrderID}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrderListInterface;